<template>
  <b-container fluid>
     <iq-card>
      <template v-slot:body>
        <b-row  v-if="authUser.warehouse_id">
          <b-col>
            <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('warehouse_config.warehouse_name')"
                label-for="warehouse_name"
                >
                <b-form-select
                  plain
                  v-model="search.warehouse_id"
                  :options="warehouseList"
                  id="warehouse_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
        </b-col>
        <b-col>
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>

    <b-overlay :show="loadingState">
      <b-row class="justify-content-center">
        <b-col md="2" sm="6" class="custom-box-shadow">
          <div class="bg-blue mt-3 p-3 rounded mb-3 text-center">
            <h4 class="text-white">{{ $n(totalService, { useGrouping: false }) }}</h4>
            <h5 class="text-white d-block">{{ $t('warehouse_service_performance.service') }}</h5>
          </div>
        </b-col>
        <b-col md="2" sm="6" class="custom-box-shadow">
          <div class="bg-success mt-3 p-3 rounded mb-3 text-center">
            <h4 class="text-white">{{ $n(totalComplain, { useGrouping: false }) }}</h4>
            <h5 class="text-white d-block">{{ $t('warehouse_service_performance.complain') }}</h5>
          </div>
        </b-col>
        <b-col md="2" sm="6" class="custom-box-shadow">
          <div class="bg-puple mt-3 p-3 rounded mb-3 text-center">
            <h4 class="text-white">{{ $n(totalDelayService, { useGrouping: false })}}</h4>
            <h5 class="text-white d-block">{{ $t('warehouse_service_performance.delay_service') }}</h5>
          </div>
        </b-col>
        <b-col md="4" sm="6" class="custom-box-shadow">
          <div class="bg-primary mt-3 p-3 rounded mb-3 text-center">
            <h5 class="text-white" >{{ $t('warehouseInfoService.actualSpace') }} : {{ $n(actualSpace, { useGrouping: false })}} <br/> {{ $t('warehouseInfoService.freeSpace') }} : {{ $n(freeSpace, { useGrouping: false })}}</h5>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
    <b-row>
      <b-col md="12">
        <iq-card>
           <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_service_performance.service_list') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" striped bordered hover :items="serviceLists" :fields="services" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                       {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(warehouse_name)="data">
                      <span class="capitalize">{{ data.item.warehouse_name }}</span>
                    </template>
                    <template v-slot:cell(warehouse_type_name)="data">
                      <span class="capitalize">{{ data.item.warehouse_type_name }}</span>
                    </template>
                    <template v-slot:cell(upazilla_name)="data">
                      <span class="capitalize">{{ data.item.upazilla_name }}</span>
                    </template>
                    <template v-slot:cell(land_area)="data">
                      {{ $n(data.item.land_area, { useGrouping: false }) }}
                    </template>
                    <template v-slot:cell(mobile)="data">
                     {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.mobile, { useGrouping: false }) }}
                    </template>
                  </b-table>
                  <b-pagination
                  align="center"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total-rows="pagination.totalRows"
                  @input="testClick"
                  />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
         <iq-card>
           <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_service_performance.complain') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="complainLists" :fields="complains" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + paginationComplain.slOffset) }}
                    </template>
                    <template v-slot:cell(warehouse_name)="data">
                      <span class="capitalize">{{ data.item.warehouse_name }}</span>
                    </template>
                    <template v-slot:cell(warehouse_type_name)="data">
                      <span class="capitalize">{{ data.item.warehouse_type_name }}</span>
                    </template>
                    <template v-slot:cell(upazilla_name)="data">
                      <span class="capitalize">{{ data.item.upazilla_name }}</span>
                    </template>
                    <template v-slot:cell(mobile)="data">
                     {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.mobile, { useGrouping: false }) }}
                    </template>
                  </b-table>
                  <b-pagination
                  align="center"
                  v-model="paginationComplain.currentPage"
                  :per-page="paginationComplain.perPage"
                  :total-rows="paginationComplain.totalRows"
                  @input="complainDataLoad"
                  />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
         <iq-card>
           <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_service_performance.delay_service') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" striped bordered hover :items="DelayServiceList" :fields="delays" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + paginationDelayService.slOffset) }}
                    </template>
                    <template v-slot:cell(warehouse_name)="data">
                      <span class="capitalize">{{ data.item.warehouse_name }}</span>
                    </template>
                    <template v-slot:cell(warehouse_type_name)="data">
                      <span class="capitalize">{{ data.item.warehouse_type_name }}</span>
                    </template>
                    <template v-slot:cell(upazilla_name)="data">
                      <span class="capitalize">{{ data.item.upazilla_name }}</span>
                    </template>
                    <template v-slot:cell(land_area)="data">
                      {{ $n(data.item.land_area, { useGrouping: false }) }}
                    </template>
                    <template v-slot:cell(mobile)="data">
                     {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.mobile, { useGrouping: false }) }}
                    </template>
                  </b-table>
                  <b-pagination
                  align="center"
                  v-model="paginationDelayService.currentPage"
                  :per-page="paginationDelayService.perPage"
                  :total-rows="paginationDelayService.totalRows"
                  @input="delayDataLoad"
                  />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
         <iq-card>
           <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_service_performance.warehouse_space') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" striped bordered hover :items="wareSpaceList" :fields="warehouseSpaces" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                       {{ $n(data.index + paginationSpace.slOffset) }}
                    </template>
                    <template v-slot:cell(warehouse_name)="data">
                      <span class="capitalize">{{ data.item.warehouse_name }}</span>
                    </template>
                    <template v-slot:cell(total_space)="data">
                      <span class="capitalize">{{ $n(data.item.total_space, { useGrouping: false }) }}</span>
                    </template>
                    <template v-slot:cell(actual_space)="data">
                      <span class="capitalize">{{ $n(data.item.actual_space, { useGrouping: false }) }}</span>
                    </template>
                    <template v-slot:cell(free_space)="data">
                      <span class="capitalize">{{ $n(data.item.free_space, { useGrouping: false }) }}</span>
                    </template>
                    <template v-slot:cell(filled_space)="data">
                      <span class="capitalize">{{ $n(data.item.filled_space, { useGrouping: false }) }}</span>
                    </template>
                    <template v-slot:cell(land_area)="data">
                      {{ $n(data.item.land_area, { useGrouping: false }) }}
                    </template>
                  </b-table>
                  <b-pagination
                  align="center"
                  v-model="paginationSpace.currentPage"
                  :per-page="paginationSpace.perPage"
                  :total-rows="paginationSpace.totalRows"
                  @input="spaceDataLoad"
                  />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { serviceMonitoringList } from '../../api/routes'
// import { serviceList } from '../../../../common-config/org-profile/api/routes'

export default {
  name: 'UiDataTable',
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      paginationComplain: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      paginationDelayService: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      paginationSpace: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      totalService: '',
      totalComplain: '',
      totalDelayService: '',
      totalSpace: '',
      actualSpace: '',
      freeSpace: '',
      filledSpace: '',
      serviceLists: [],
      complainLists: [],
      DelayServiceList: [],
      wareSpaceList: [],
      total_space: '',
      search: {
        warehouse_id: 0
      },
      testId: 0,
      rows: [],
      regionLists: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      warehouseNameList: [],
      farmerList: []
    }
  },
  computed: {
    authUser () {
      return this.$store.state.Auth.authUser
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    listData () {
      return this.$store.state.WarehouseService.recommendations
    },
    services () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('warehouse_information.district'), class: 'text-center' },
          { label: this.$t('warehouse_information.upazilla'), class: 'text-center' },
          { label: this.$t('warehouse_service.union'), class: 'text-center' },
          { label: this.$t('warehouse_config.warehouse_name'), class: 'text-center' },
          { label: this.$t('warehouse_service_performance.farmer_name'), class: 'text-center' },
          { label: this.$t('warehouse_service.mobile'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'distict_name_bn' },
          { key: 'upzila_name_bn' },
          { key: 'union_name_bn' },
          { key: 'warehouse_name_bn' },
          { key: 'farmer_name_bn' },
          { key: 'mobile' }
        ]
      } else {
        keys = [
        { key: 'index' },
          { key: 'distict_name' },
          { key: 'upzila_name' },
          { key: 'union_name' },
          { key: 'warehouse_name' },
          { key: 'farmer_name' },
          { key: 'mobile' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    complains () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('warehouse_information.district'), class: 'text-left' },
          { label: this.$t('warehouse_information.upazilla'), class: 'text-left' },
          { label: this.$t('warehouse_service.union'), class: 'text-left' },
          { label: this.$t('warehouse_config.warehouse_name'), class: 'text-left' },
          { label: this.$t('warehouse_service_performance.farmer_name'), class: 'text-left' },
          { label: this.$t('warehouse_service.mobile'), class: 'text-left' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'distict_name_bn' },
          { key: 'upzila_name_bn' },
          { key: 'union_name_bn' },
          { key: 'warehouse_name_bn' },
          { key: 'farmer_name_bn' },
          { key: 'mobile' }
        ]
      } else {
        keys = [
        { key: 'index' },
          { key: 'distict_name' },
          { key: 'upzila_name' },
          { key: 'union_name' },
          { key: 'warehouse_name' },
          { key: 'farmer_name' },
          { key: 'mobile' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    delays () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('warehouse_information.district'), class: 'text-left' },
          { label: this.$t('warehouse_information.upazilla'), class: 'text-left' },
          { label: this.$t('warehouse_service.union'), class: 'text-left' },
          { label: this.$t('warehouse_config.warehouse_name'), class: 'text-left' },
          { label: this.$t('warehouse_service_performance.farmer_name'), class: 'text-left' },
          { label: this.$t('warehouse_service.mobile'), class: 'text-left' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'distict_name_bn' },
          { key: 'upzila_name_bn' },
          { key: 'union_name_bn' },
          { key: 'warehouse_name_bn' },
          { key: 'farmer_name_bn' },
          { key: 'mobile' }
        ]
      } else {
        keys = [
        { key: 'index' },
          { key: 'distict_name' },
          { key: 'upzila_name' },
          { key: 'union_name' },
          { key: 'warehouse_name' },
          { key: 'farmer_name' },
          { key: 'mobile' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    warehouseSpaces () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('warehouse_config.warehouse_name'), class: 'text-left' },
          { label: this.$t('warehouse_service_performance.total_space'), class: 'text-left' },
          { label: this.$t('warehouse_service_performance.actual_space'), class: 'text-left' },
          { label: this.$t('warehouse_service_performance.free_space'), class: 'text-left' },
          { label: this.$t('warehouse_service_performance.filled_space'), class: 'text-left' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'warehouse_name_bn' },
          { key: 'total_space' },
          { key: 'actual_space' },
          { key: 'free_space' },
          { key: 'filled_space' }
        ]
      } else {
        keys = [
        { key: 'index' },
          { key: 'warehouse_name' },
          { key: 'total_space' },
          { key: 'actual_space' },
          { key: 'free_space' },
          { key: 'filled_space' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    warehouseTypeList: function () {
      return this.$store.state.warehouse.warehouseTypeList.filter(item => item.status === 0)
    }
  },
  watch: {
    loadingState: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    this.loadData()
     if (this.authUser.warehouse_id) {
      const warehouseId = this.authUser.warehouse_id
      this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(warehouseInfoItem => warehouseInfoItem.value === warehouseId)
      this.search.warehouse_id = warehouseId
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    resetId () {
      this.testId = 0
    },
    testClick () {
      this.loadServiceData()
    },
    complainDataLoad () {
      this.loadComplainData()
    },
    delayDataLoad () {
      this.loadDelayData()
    },
    spaceDataLoad () {
      this.loadSpaceData()
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    paginationComplainData (data) {
      this.paginationComplain.currentPage = data.current_page
      this.paginationComplain.totalRows = data.total
      this.paginationComplain.slOffset = this.paginationComplain.perPage * this.paginationComplain.currentPage - this.paginationComplain.perPage + 1
    },
     paginationDelayServiceData (data) {
      this.paginationDelayService.currentPage = data.current_page
      this.paginationDelayService.totalRows = data.total
      this.paginationDelayService.slOffset = this.paginationDelayService.perPage * this.paginationDelayService.currentPage - this.paginationDelayService.perPage + 1
    },
     paginationSpaceData (data) {
      this.paginationSpace.currentPage = data.current_page
      this.paginationSpace.totalRows = data.total
      this.paginationSpace.slOffset = this.paginationSpace.perPage * this.paginationSpace.currentPage - this.paginationSpace.perPage + 1
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
       const response = await RestApi.getData(authServiceBaseUrl, '/user/farmer-details')
        if (response.success) {
          this.farmerList = response.data
        }
      const result = await RestApi.getData(warehouseServiceBaseUrl, serviceMonitoringList, params)
        if (result.success) {
            this.totalService = result.data.totalService
            this.totalComplain = result.data.totalComplain
            this.totalDelayService = result.data.totalDelayService
            this.totalSpace = result.data.totalSpace
            this.actualSpace = result.data.actualSpace
            this.freeSpace = result.data.freeSpace
            this.filledSpace = result.data.filledSpace
            this.serviceLists = this.relationalData(result.data.serviceList)
            this.complainLists = this.relationalData(result.data.complainList)
            this.DelayServiceList = this.relationalData(result.data.delayServiceList)
            this.wareSpaceList = this.relationalData(result.data.warehouseSpaceList)
            this.paginationData(result.data.serviceList)
            this.paginationComplainData(result.data.complainList)
            this.paginationDelayServiceData(result.data.delayServiceList)
            this.paginationSpaceData(result.data.warehouseSpaceList)
        }
         this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    async loadComplainData () {
      const params = Object.assign({}, this.search, { page: this.paginationComplain.currentPage, per_page: this.paginationComplain.perPage })
       const response = await RestApi.getData(authServiceBaseUrl, '/user/farmer-details')
        if (response.success) {
          this.farmerList = response.data
        }
      const result = await RestApi.getData(warehouseServiceBaseUrl, serviceMonitoringList, params)
        if (result.success) {
            this.complainLists = this.relationalData(result.data.complainList)
            this.paginationComplainData(result.data.complainList)
        }
         this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
   async loadDelayData () {
      const params = Object.assign({}, this.search, { page: this.paginationDelayService.currentPage, per_page: this.paginationDelayService.perPage })
       const response = await RestApi.getData(authServiceBaseUrl, '/user/farmer-details')
        if (response.success) {
          this.farmerList = response.data
        }
      const result = await RestApi.getData(warehouseServiceBaseUrl, serviceMonitoringList, params)
        if (result.success) {
            this.DelayServiceList = this.relationalData(result.data.delayServiceList)
            this.paginationDelayServiceData(result.data.delayServiceList)
        }
         this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    async loadSpaceData () {
      const params = Object.assign({}, this.search, { page: this.paginationSpace.currentPage, per_page: this.paginationSpace.perPage })
       const response = await RestApi.getData(authServiceBaseUrl, '/user/farmer-details')
        if (response.success) {
          this.farmerList = response.data
        }
      const result = await RestApi.getData(warehouseServiceBaseUrl, serviceMonitoringList, params)
        if (result.success) {
            this.wareSpaceList = this.relationalData(result.data.warehouseSpaceList)
            this.paginationSpaceData(result.data.warehouseSpaceList)
        }
         this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    async loadServiceData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
       const response = await RestApi.getData(authServiceBaseUrl, '/user/farmer-details')
        if (response.success) {
          this.farmerList = response.data
        }
      const result = await RestApi.getData(warehouseServiceBaseUrl, serviceMonitoringList, params)
        if (result.success) {
            this.serviceLists = this.relationalData(result.data.serviceList)
            this.paginationData(result.data.serviceList)
        }
         this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    relationalData (data) {
      const recommendationData = data.data.map(item => {
        const districtData = this.$store.state.commonObj.districtList.find(listitem => listitem.value === item.district_id && listitem.status === 0)
        const upazilaData = this.$store.state.commonObj.upazilaList.find(listitem => listitem.value === item.upazilla_id && listitem.status === 0)
        const unionData = this.$store.state.commonObj.unionList.find(listitem => listitem.value === item.union_id && listitem.status === 0)
        const wareHouseData = this.$store.state.warehouse.warehouseInfoList.find(listitem => listitem.value === item.warehouse_id && listitem.status === 0)
        const farmarData = this.farmerList.find(listitem => listitem.username === item.farmer_id)
        const tmpBindingData = {
          distict_name: districtData !== undefined ? districtData.text_en : '',
          distict_name_bn: districtData !== undefined ? districtData.text_bn : '',
          upzila_name: upazilaData !== undefined ? upazilaData.text_en : '',
          upzila_name_bn: upazilaData !== undefined ? upazilaData.text_bn : '',
          union_name: unionData !== undefined ? unionData.text_en : '',
          union_name_bn: unionData !== undefined ? unionData.text_bn : '',
          warehouse_name: wareHouseData !== undefined ? wareHouseData.text_en : '',
          warehouse_name_bn: wareHouseData !== undefined ? wareHouseData.text_bn : '',
          farmer_name: farmarData !== undefined ? farmarData.name : '',
          farmer_name_bn: farmarData !== undefined ? farmarData.name_bn : '',
          mobile: farmarData !== undefined ? farmarData.username : ''
        }
       return Object.assign({}, item, tmpBindingData)
      })
      return recommendationData
    }
  }
}
</script>

<style>
.bg-blue {
  color: #1068c6 !important
}
.bg-puple {
  background: #7b73db !important;
}
</style>
